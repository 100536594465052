.custSwiper .swiper-button-prev{
display: none;
}
.custSwiper .swiper-button-next{
display: none;
}
.slider-title{
    background: linear-gradient(126.56deg, #D98900 22.76%, #ED1B23 126.07%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
   font-weight: 700;
   font-size: 30px;

}
.custdiv-middle{
    background-color: #D9890133;

}

.cust-img{
    display: block;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
}

.cust-detail{
    border-top: 1px solid #C1C1C1;
    border-bottom: 1px solid #C1C1C1;
    padding: 10px 0px;
    text-align: center;
}
.cust-detail1{
    border-right: 1px solid #C1C1C1;
}

.cust-heading{
    color: #1D1D1D;
    font-size: 26px;
    font-weight: 700;
}
.cust-stat1{
    color: #080054;
    font-weight: 700;
    font-size: 24px;
}
.cust-stat2{
color: #747474;
font-size: 16px;
}

@media (max-width:768px){
    .custdiv-right{
        display: none;
    }
    .custdiv{
        flex-direction: column-reverse;
    }
}