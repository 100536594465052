.navbar {

  /* background-color: #fef7e5; */
  position: relative;
}
.header-logo{
  width: 120px;
  height: 120px;
}
.menu-icon {
  display: none;
}

.nav-elements {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-elements ul {
  display: flex;

  list-style-type: none;
}

.nav-elements ul .li:not(:last-child) {
  margin-right: 30px;
  font-weight: 500;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

.custom-btn {

  padding: 10px 30px;
  gap: 10px;
  text-align: left;
  color: white;
  border-radius: 5px;
  opacity: 0px;

  background: linear-gradient(126.56deg, #D98900 22.76%, #ED1B23 126.07%);

}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }

  .s2img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .logo {
    z-index: 999;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    flex-direction: column;
    right: 0;
    top: 75px;
    align-items: start;
    background-color: white;
    width: 0px;
    z-index: 999;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 250px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;

  }

  .custom-btn {
    margin-left: 30px;
    padding: 10px 30px;
    gap: 10px;
    text-align: left;
    color: white;
    /* margin-top: 10px; */
    border-radius: 5px;
    opacity: 0px;
    text-wrap: nowrap;

    background: linear-gradient(126.56deg, #D98900 22.76%, #ED1B23 126.07%);

  }
}


/* SECTION 1 STARTS */
.section1 {
  display: flex;

}

.section1left {
  width: 100%;
  height: 100%;
  background-image: url('../public/assets/images/section1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: row;

}

.section1right {
  width: 100%;
  height: 100%;
  background-color: red;
}

.section1heading {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
}

.section1para {
  font-size: 14px;
}

.section1left .swiper {
  width: auto;
  margin: 45px 48px !important;

}


.section1left .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;

  object-fit: cover;
}

@media (min-width:1200px) and (max-width:1400px) {
  .section1heading {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
  }
}

@media (min-width:985px) and (max-width:1200px) {
  .section1heading {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
  }

  .section1left .swiper {
    width: auto;
    margin: 45px 30px !important;
    margin-top: 100px !important;

  }
}

@media (min-width:1278px) {
  /* .section1left{
        width: 430px;
        height: 416px;
    } */
}

.s1div {
  display: none;
}

@media (max-width:995px) {
  .section1 {
    flex-direction: column-reverse;
  }

  .section1left {
    background: #002B54;
    display: block;
    /* flex-direction: column; */
    width: 100%;
  }

  .s1div {
    display: block;
    width: 100%;
    margin-top: -15%;


  }
}

.mySwiper2 {
  margin: 0px !important;
  z-index: -1 !important;
}

.swiper-nav-prev {
  box-shadow: none !important;
  background-color: #D98900;
  border: none;
}

.swiper-nav-next {
  box-shadow: none !important;
  background-color: #D98900;
  border: none;
}


/* SECTION 2 STARTS */

.section2 {
  width: 100%;
  height: 100%;

  background: #002B54;
}

.s2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url('../public/assets/images/section2.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.s2heading {
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;


  background: linear-gradient(126.56deg, #D98900 22.76%, #ED1B23 126.07%);

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

}


.custom-btn2 {
  width: fit-content;
  gap: 10px;
  text-align: left;
  color: white;
  border-radius: 5px;
  text-wrap: nowrap;
  background: linear-gradient(126.56deg, #D98900 22.76%, #ED1B23 126.07%);

}


/* SECTION 3 STARTS */

.s3 {
  border-radius: 10px;
  /* border: 1px solid black; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 100%;
  height: 100%;
}

.s3heading {
  text-transform: capitalize;
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;

}

.news {
  width: 287px;
  border-radius: 10px;
height: 100%;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.384) 0px 1px 4px;
  background-color: whitesmoke;
}

.swiper-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  /* Prevent overflow issues */
}


.news-bottom {
  background-color: #fff;
  border-radius: 0px 0px 10px 10px;
  padding: 5px 20px;
}

.news-section .swiper-wrapper {
  padding: 30px 0px;
}

.ln-stroke {
  width: 250px;
  height: 2px;

  background: linear-gradient(111.73deg, #D98900 20.98%, rgba(237, 27, 35, 0) 52.63%);




}
.msp .swiper-slide{
 
}
@media (max-width:465px) {
  .ln-stroke {
    display: none;
  }
  .header-logo{
    width: 80px;
    height: 80px;
  }
}

.s5 {
  width: 100%;
  height: 100%;
  background-image: url('../public/assets/images/section5.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.s6teams {
  width: 134px;
  height: 134px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.s6teams img {
  width: 100%;
  height: 100%;
}

.s7card {
  width: 247px;
  height: 290px;
  background-image: url('../public/assets/images/s7background.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: center;
}

.s7playername {
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #002B54;
}

.pname {
  margin-bottom: 0px;
  text-transform: uppercase;
}

@media (max-width:446px) {
  .s7player img {
    width: 164px;
    height: 150px;
  }

  .s7card {

    height: 200px;
  }
}



.s7row {

  display: flex;
 
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.s7column {
object-fit:cover;
  max-width: 33.33%;
  padding: 0 4px;

}

.custom-scrollbar::-webkit-scrollbar{
  height: 2px;
  width: 100%;
}
.custom-scrollbar::-webkit-scrollbar-thumb{
  height: 2px;
  width: auto;
  color: red;
}

.custom-scrollbar div{
  user-select: none;
}
.instagram-media {
  margin: 0 auto !important;
  max-width: 100% !important;
}

/* General styling for the container */
.s7column.gallery-images {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 8px;
  cursor: pointer;
}

/* Zoom effect on hover */
.s7column.gallery-images:hover {
  transform: scale(1.1); /* Slightly enlarge the element */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

/* Ensure the image fills the container and adapts */
.gallery-image {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  object-fit: cover; /* Maintain aspect ratio and crop overflow */
}

/* Subtle zoom for the image on hover */
.s7column.gallery-images:hover .gallery-image {
  transform: scale(1.1); /* Slightly zoom the image */
}


.s7column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .s7column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .s7column {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

.s9 {
  background-image: url('../public/assets/images/s9img.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.jerseyname {
  background: linear-gradient(126.56deg, #D98900 22.76%, #ED1B23 126.07%);

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 36px;
  font-weight: 500;
  font-family: "edo";
}

.s9logo {
  position: absolute;
  width: 150px;
  height: 150px;
  right: 0;
}

@media (max-width: 992px) {
  .s9logo {
    display: none;
  }
}


.custdiv {
  height: 100%;
}

.swiper-nav-btns2 {
  width: 100%;
  justify-content: space-between;
  z-index: 1;

}

.footer {
  background-image: url('../public/assets/images/footer.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.aboutus-heading {
  max-width: 800px !important;
}

.footer-heading {
  font-size: 18px;
  font-weight: 700;
  background: linear-gradient(126.56deg, #D98900 22.76%, #ED1B23 126.07%);

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.footer-li {
  color: #C1C1C1;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.footer-li:hover {
  color: white;
}

.footer-small {
  font-size: 10px;
  cursor: pointer;
}

.teampage {
  background-image: url('../public/assets/images/teambackground.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.player-card {
  width: 276px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.pgback {
  width: 276px;
  height: 236px;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  align-items: end;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../public/assets/images/playerbackground.png');
}


.team-title {
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  text-align: center;

}

.player-name {
  text-transform: uppercase;
}

.player-name2 {
  font-weight: 500;
  background: linear-gradient(126.56deg, #D98900 22.76%, #ED1B23 126.07%);
text-transform: capitalize;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.press1 {
  height: 246px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.press2 {
  background-color: #D8E0EB6B;
  
}

.press {
  border-radius: 10px;
  color: black;
  text-decoration: none;
  height: 100%;
    display: block;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 400px) {
  .section1left .swiper {
    margin: 45px 20px !important;
  }
}

@media (max-width: 768px) {
  .s3heading {
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
  }

  .home-player {
    background-image: none !important;
  }
}

.s1bg {
  background-image: url('../public/assets/images/s1bg2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -5;
}

.home-player {
  background-image: url('../public/assets/images/playerback.png');
  background-repeat: no-repeat;
  background-position: top left;
}

.cls1 {
  display: flex;
}

.bcl-1 {

  background-image: url('../public/assets/images/bclbackground.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 12px;
}

.contact-us {

  background-image: url('../public/assets/images/contactus.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 12px;
  height: 100%;
}

.bcl-heading {
  font-family: Edo;
  font-size: 35px;
  font-weight: 400;

  text-align: left;
  color: white;
  text-transform: uppercase;
}

.bcl-heading2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
  stroke: black;
}

.bcl-tag {
  /* Vector 13 */

  max-width: 427px;
  height: 56px;
  display: flex;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 92% 100%, 0% 100%);
  color: white;
  background: linear-gradient(126.56deg, #D98900 22.76%, #ED1B23 126.07%);

}

.bcl-heading3 {
  color: rgba(29, 29, 29, 1);

  font-size: 40px;
  font-weight: 700;

}

.bcl-para3 {
  color: rgba(116, 116, 116, 1);
  line-height: 24px;
}

.bcl-s3 {
  background-image: url('../public/assets/images/bclbackground2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media(max-width:768px) {
  .bcls1 {
    flex-direction: column-reverse;
  }

  .bcl-heading2 {
    font-size: 20px;
  }

  .bcl-heading3 {
    font-size: 30px;
    font-weight: 700;
  }

  .bcl-heading {
    font-family: Edo;
    font-size: 30px;
  }
}

.cu-icon {
  width: 67px !important;
  height: 67px !important;
  border-radius: 50%;
  border: 2px 0px 0px 0px;
  opacity: 0px;
  border: 2px solid rgba(217, 137, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;



}

.custom-map{
  width: 100%;
  height: 450px;
}

